/*@import url("styles/colors.css");*/

/*for the zero card*/
body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  background: #fff;
}

html {
  font-size: 16px !important;
}

a:hover {
  text-decoration: none !important;
}

span .caret {
  display: none;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 0ms ease-in;
}
